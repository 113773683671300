@function assets-url($path) {
  @return url("/assets/#{$path}");
}

// settings
@import 'settings/breakpoints';
@import 'settings/colors';
@import 'settings/fonts';
@import 'settings/z-indexes';

// helpers
@import 'helpers/convert';
@import 'helpers/typography';
@import 'helpers/breakpoints';
@import 'helpers/textstyle';
@import 'helpers/theme';
@import 'helpers/ie11fixes';

// Base document
@import 'base/document';

// Everything below is namespaced into #advice-robo-pcs
#advice-robo-pcs {
  // vendor libraries
  @import '~react-id-swiper/src/styles/css/swiper.css';

  // base
  @import 'base/every'; // prio
  @import 'base/button';
  @import 'base/elements';
  @import 'base/form';
  @import 'base/icons';

  // objects
  @import 'objects/giphy';
  @import 'objects/indicator';
  @import 'objects/intro';
  @import 'objects/lightbox';
  @import 'objects/page';
  @import 'objects/permission';
  @import 'objects/processing';
  @import 'objects/prompt';
  @import 'objects/section';
  @import 'objects/services';
  @import 'objects/spinner';
  @import 'objects/step';
  @import 'objects/watermark';

  // pages
  @import 'templates/intro';
  @import 'templates/primary';
  @import 'templates/step';
}
