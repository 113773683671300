// This media query matches only IE browsers
@media all and (-ms-high-contrast:none) {
  #advice-robo-pcs {
    // These aren't transparent so look ugly, we just hide them for IE
    .watermark {
      display: none;
    }

    // Needs a fixed height because IE doesn't handle flex properly
    .intro {
      height: 500px;
    }

    // Needs a fixed height because IE doesn't handle flex properly (intro height + some space for the get started button)
    .template-intro {
      height: 600px;
    }

    // Needs a fixed height because IE doesn't handle flex properly
    .template-step {
      height: 850px;
    }

    .step-option {
      // Reduce font size of answers, because they need to always fit within the limited template-step height
      input[type="radio"] + label {
        @include desktop {
          font-size: 1rem !important;
        }
      }

      // Disable pointer events on image answers, because they won't register as clicking on the answer
      &--image__figure {
        pointer-events: none;
      }
    }

    // Because of flex/fixed heights, the margin of the top image of an image answer is way too large
    .step__image {
      margin-bottom: -50px;
    }
  }
}