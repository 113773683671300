form {

  .step-choice {

    input[type="radio"] {
      display: none;

      + label {
        @include textstyle-semibold;
        padding: 4px;
        width: 96px;
        height: 96px;
        color: color(questionAnswerText);
        border-radius: 50%;
        border: 1px dashed color(questionAnswerHelper);
        vertical-align: middle;
        display: table-cell;
        text-align: center;
        font-size: rem(16px);
        line-height: rem(16px);
        user-select: none;
        cursor: pointer;
      }

      &:checked + label {
        background-color: color(questionHeadBg);
        color: white;
        border-width: 0;
      }
    }
  }

  .step-option {

    input[type="radio"] {
      display: none;

      + label {
        min-height: 20px;
        box-shadow: 0 1px 2px 0 rgba($color-black, .2);
        border-radius: 12px;
        display: flex;
        align-items: center;
        width: 100%;
        color: $color-black;
        padding: 10px 32px 10px 16px;
        cursor: pointer;
        position: relative;
        font-size: 16px;
        line-height: 20px;

        @include landscape {
          @include typography-paragraph;
          padding: 24px 32px 24px 16px;
        }

        // animation
        background-color: color(questionAnswerBg);
        transition: background-color 200ms ease-in-out;

        &:hover {
          background-color: rgba($color-black, .01);
        }

        .step-option__icon {
          margin-right: 12px;
          font-size: 24px;
          height: 24px;
          color: color(questionAnswerHelper);
          line-height: 0.77; // Correct for font icons not starting at exact top, causing them to render lower than expected

          @include desktop {
            margin-right: 12px;
            height: 28px;
            font-size: 28px;
          }
        }

        .step-option__check {
          width: 20px;
          height: 20px;
          background-color: color(questionAnswerBg);
          border-radius: 50%;
          font-size: 10px;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 12px;
          line-height: 20px;
          color: color(questionAnswerText);
          text-align: center;
          opacity: 0;
        }
      }

      &:checked + label {
        background-color: color(questionAnswerActive);
        color: color(questionAnswerActiveText);

        .step-option__check {
          opacity: 1;
        }

        .step-option__icon {
          color: color(questionAnswerActiveText);
        }
      }
    }

    &--image {
      display: inline-block;
      width: calc(50% - 24px);
      margin: 8px 12px !important;

      @include landscape {
        width: calc(25% - 12px);
        margin: 4px 6px !important;
      }

      input[type="radio"] {

        + label {
          padding: 12px 16px;
          height: auto;

          @include landscape {
            padding: 8px 8px 3px 8px;
          }

          .step-option__check {
            color: color(questionHeadBg);
          }
        }

        &:checked + label {
          background-color: color(questionAnswerBg);
          border: 1px solid color(questionHeadBg)
        }
      }

      &__figure {
        width: 100%;
        text-align: center;
        margin: 0;

        img {
          max-width: 100%;
        }
      }
    }

    &--numeric {
      input[type="number"] {
        box-shadow: 0 1px 2px 0 rgba($color-black, .2);
        border-radius: 10px;
        display: flex;
        text-align: right;
        margin: auto;
        color: $color-black;
        padding: 0.2rem;
        position: relative;
        font-size: 1.5rem;
        width: 30%;
      }
    }

    &--text {
      input {
        box-shadow: 0 1px 2px 0 rgba($color-black, .2);
        border-radius: 10px;
        display: flex;
        text-align: right;
        margin: auto;
        color: $color-black;
        padding: 0.2rem;
        position: relative;
        font-size: 1.5rem;
        width: 70%;
      }
    }
  }
}